import React from 'react'

const ConversionSection = () => {
  return (
    <section className="conversion-section">
      <h1>
        ליצירת קשר עם 
        <span> שרה טייב </span>
        חייגו:
        <a tabIndex="8" href="tel:0542400848">054-2400848</a>
      </h1>
    </section>
  )
}

export default ConversionSection
