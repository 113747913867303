import React from 'react'

const Article = () => {
  return (
    <div>
      <h1>one article by id</h1>
    </div>
  )
}

export default Article
