import React from 'react';
import 'react-responsive-modal/styles.css';

import HeaderSection from '../components/HeaderSection';
import AboutSection from '../components/AboutSection.jsx';
import ConversionSection from '../components/ConversionSection.jsx';
import FieldsSection from '../components/FieldsSection.jsx';
import MethodsSection from '../components/MethodsSection.jsx';
import ContactSection from '../components/ContactSection.jsx';
import FooterSection from '../components/FooterSection.jsx';

const Main = () => {

  return (
    <div className="main-page">
      <HeaderSection type="main" header=""/>
      <ConversionSection/>
      <FieldsSection/>
      <AboutSection/>
       <MethodsSection/>
      <ContactSection/>
      <FooterSection/>
    </div>
  )
}

export default Main
