import React from 'react'

const ArticlesList = () => {
  return (
    <div>
      <h1>articles list:</h1>
    </div>
  )
}

export default ArticlesList
