import React, {useState} from 'react';

const Navbar = () => {

  const [menuIsDisplay, setMenuIsDisplay] = useState(false)

  const toggleMenu = () => {
    setMenuIsDisplay(!menuIsDisplay);
  }

  const closeMenu = () => {
    setMenuIsDisplay(false);
  }

  return (
    <div className="Navbar">
     <nav>
       <a href="/" className="logo" tabIndex="1">
        <img src="/images/logo.png" alt="לוגו שרה טייב פסיכולוגית"/>
       </a>
        <button tabIndex="2" className="menu-toggler" onClick={toggleMenu}>
         <i className="fa fa-bars"></i>
        </button>
       <ul className={`${menuIsDisplay ? "display" : ""}`}>
         <li onClick={closeMenu}>
           <a tabIndex="3" href="/#main">בית</a>
         </li>
         <li onClick={closeMenu}>
           <a tabIndex="4" href="/#fields">תחומי טיפול</a>
         </li>
         <li onClick={closeMenu}>
           <a tabIndex="5" href="/#about">אודות</a>
         </li>
         <li onClick={closeMenu}>
           <a tabIndex="6" href="/#methods">שיטות</a>
         </li>
         <li onClick={closeMenu}>
           <a tabIndex="7" href="/#contact">יצירת קשר</a>
         </li>
       </ul>
     </nav>
    </div>
  )
}

export default Navbar
