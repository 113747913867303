import React from 'react';
import Navbar from './Navbar';
import ImageMain from './ImageMain';

const HeaderSection = (props) => {

  const {type, header} = props;

  return (
    <header className="HeaderSection" id="main">
      <Navbar/>
      <ImageMain type={type} header={header}/>
    </header>
  )
}

export default HeaderSection
