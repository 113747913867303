
export const personalDevelopment = {
  title: "התפתחות אישית ומשברים",
  text: "כמו כל תהליך התפתחותי, ישנם שלבים שצריך לעבור אותם ולהתמקם מחדש מול עצמינו ומול הסביבה שלנו. ככל שנעביר את המודעות העצמית שלנו, וככל שניהיה מחוברים לחלקים השונים של האישיות שלנו, כך נוכל לצמוח יותר ולהרחיב את הזהות שלנו. משברים הם לחלק בלתי נפרד במסע הזה. לפעמים הם מגיעים מבחוץ ולפעמים מבפנים. הטיפול מאפשר להתבונן, לבטא את הכאב, לזהות צרכים ושאיפות ולייצר מציאות מותאמת ומיטיבה יותר לנפש.",
}
 

export const adolescence = {
  title: "גיל ההתבגרות",
  text: "גיל ההתבגרות הוא תקופת חיים בעלת משמעות מרכזית להתפתחות העצמי של כל נער ונערה. כיום, מדובר על תקופה מתמשכת המשתרעת בין הגילאים 10 ל-29. תקופה זו ידועה כמאופיינת בסערות רגשיות מצד המתבגרים, בשל שינויים בהתפתחותם הרגשית, הפיזית, הקוגניטיבית והחברתית. בתקופת מעבר זו, המשימות התפתחותיות המרכזיות הן עצמאות מול תלות, היפרדות מההורים , גיבוש זהות עצמאית וייחודית, ויצירת קשרים עם קבוצת השווים. למולם ניצבים הורים, האמורים לתמוך בהם וללוותם בתהליך העובר עליהם. לעיתים, חשים ההורים בלבול, ואף חוסר אונים וידע כיצד להגיב, בשל המורכבות ההתנהגותית שמציבים בפניהם המתבגרים. הליווי, ההדרכה והטיפול, הן במתבגרים והן בהורים, מאפשרת הבנה, נורמליזציה, הרגעה, ותקשורת טובה יותר.",
}

export const risk = {
  title: " נוער (ומבוגרים) בסיכון",
  text: "גיל ההתבגרות הינו גיל של שינויים רבים, פיזיים ורגשיים. שינויים אלו גורמים להצפה הורמונאלית ורגשית ויוצרים חוויה של בלבול ואי שקט רב. כל אלו משתקפים בעולם התוכן הפנימי והחיצוני של המתבגר וגורמים לנו המבוגרים להתבלבל יחד איתו. תקופת מעבר זו יכולה להוביל למצבי סיכון כשהיא נפגשת עם משברים אחרים בחיי הנער/ה הטיפול יוביל להבנה של התהליכים ושל הצרכים , ולחיפוש דרכים חלופיות למענה.",
}

export const relationships = {
  title: "זוגיות",
  text: "מריבות, שחיקה זוגית, מאבקי כוחות, קונפליקטים סביב סגנון ההורות, פערים בצרכים המיניים... אלו הן רק חלק מהדוגמאות איתן מתמודדים זוגות רבים לאורך תקופות שונות של חייהם המשותפים. טיפול זוגי יכול לסייע לזוג לפתור משברים כאלו, וליהנות מחיים זוגיים מספקים יותר. הטיפול הזוגי גם נותן מענה למשברים גדולים יותר כגון בגידה, שבירת אמון ברובד בכלכלי, שינוי דרמטי אצל אחד מבני הזוג. במקרים אלו, יש צורך להבין לעומק את המניעים ואת צרכים הרגשיים של כל אחד כדי להתקדם ולקבל החלטות. ככל שנטפל מוקדם יותר, כך הסיכוי לפתור, לחזק לשקם, גדול יותר.",
}

export const parenthood = {
  title: "הורות ומשפחה",
  text: "ההורות מפגישה אותנו עם הילד שבנו, עם הפחדים הקיומיים, החולשה, חסר האונים , התסכול ועוד הרבה רגשות. הורות מיטיבה היא הורות שרואה את הצרכים של הילד/ה ואת צרכיי ההורים גם יחד, ונותנת מענה חומרי, ריגשי וחברתי מתוך אחריות. כמו כל מערכת יחסים, ישנה השפעה הדדית בין ההורה לילד/ה, וזה לפעמים מסתבך והופך למעגל שלישי שמזין את עצמו ללא מוצא. ההתבוננות, השיח, ההבנה והרצון להיטיב, יאפשרו לשנות דפוסים מזיקים לקשר בריא יותר.",
}

export const migration = {
  title: "הסתגלות לאחר עליה מצרפת",
  text: "העלייה מפגישה את הפרטים ואת המשפחה עם תרבות חדשה, קודים שונים, קצב ואקלים חברים וזה דורש הבנה, הפנמה והסתגלות. כל מעבר נורמטיבי של התפתחות הופך למורכב יותר ולפעמים קהוטי. יש צורך בנורמליזציה, הנגשת התרבות הישראלית והתאמה אישית למציאות החדשה.",
}