import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import * as importedModalData from '../data/modalData.jsx';

const FieldsSection = () => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();

  const onOpenModal = (modalType) => {
    console.log(modalType, )
    setModalData(importedModalData[modalType]);
    console.log(importedModalData[modalType])
    setOpen(true)
  };

  const onCloseModal = () => setOpen(false);

  const onKeyPressed = (e, modalType) => {
    if (e.key === "Enter") {
      onOpenModal(modalType);
    }
  }
              
  return (
    <section className="fields-section" id="fields">
      <div className="section-header">
        <h2>תחומי טיפול</h2> <span>(לחיצה על תמונה לפרטים נוספים)</span>
      </div>   
      <div className="fields-wrapper">
        <div className="field-item" 
        onKeyDown={(e) => onKeyPressed(e, "personalDevelopment")}
        tabIndex="9"  onClick={() => onOpenModal("personalDevelopment")}>
          <div className="field-icon">
            <img src="images/karate.jpg" alt=""/>
          </div>
          <div className="field-text">
            התפתחות אישית ומשברים
          </div>
        </div>
        <div className="field-item" 
        onKeyDown={(e) => onKeyPressed(e, "adolescence")}
        tabIndex="10" onClick={() => onOpenModal("adolescence")}>
          <div className="field-icon">
            <img src="images/skate.jpg" alt=""/>
          </div>
          <div className="field-text">
          גיל ההתבגרות
          </div>
        </div>
        <div className="field-item" 
        onKeyDown={(e) => onKeyPressed(e, "risk")}
        tabIndex="11" onClick={() => onOpenModal("risk")}>
          <div className="field-icon">
            <img src="images/railroad.jpg" alt=""/>
          </div>
          <div className="field-text">
          נוער (ומבוגרים) בסיכון
          </div>
        </div>
        <div className="field-item" 
        onKeyDown={(e) => onKeyPressed(e, "relationships")}
        tabIndex="12" onClick={() => onOpenModal("relationships")}>
          <div className="field-icon">
            <img src="images/couple.jpg" alt=""/>
          </div>
          <div className="field-text">
          זוגיות
          </div>
        </div>
        <div className="field-item" 
        onKeyDown={(e) => onKeyPressed(e, "parenthood")}
        tabIndex="13" onClick={() => onOpenModal("parenthood")}>
          <div className="field-icon">
            <img src="images/family.jpg" alt=""/>
          </div>
          <div className="field-text">
          הורות ומשפחה
          </div>
        </div>
        <div className="field-item" 
        onKeyDown={(e) => onKeyPressed(e, "migration")}
        tabIndex="14" onClick={() => onOpenModal("migration")}>
          <div className="field-icon">
            <img src="images/migrate.jpg" alt=""/>
          </div>
          <div className="field-text">
          הסתגלות לאחר עליה מצרפת
          </div>
        </div>
      </div>
      <div>
        <Modal open={open} onClose={onCloseModal} center>
          {modalData &&
          <>
            <h2>{modalData.title}</h2>
            <p>{modalData.text}</p>
            <button className="custom-close" onClick={onCloseModal}>סגירה</button>
          </>
          }
        </Modal>
      </div>
    </section>
  )
}

export default FieldsSection
