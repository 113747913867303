import React from 'react'

const ImageMain = (props) => {

  const {type, header} = props;

  return (
    <div className={`image-main ${type === "main" ? "" : "sub-page"}`}>
      <img className="desktop" src="/images/clinic.jpg" alt="המחשת תמונה של קליניקה"/>
      <img className="mobile" src="/images/clinic-mobile.jpg" alt="המחשת תמונה קליניקה מובייל"/>
      { header !=="" && <h1 className="img-overlay">{header}</h1>}
    </div>
  )
}

export default ImageMain
