import React from 'react';

const AboutSection = () => {
  return (
    <section className="about-section" id="about"> 
      <div className="section-header">
        <h2> קצת על 
          <span>
             שרה טייב, פסיכולוגית
          </span>
        </h2>      
      </div>
      <div className="about-wrapper">
        <div className="text">
        את דרכה בעולם הטיפול והפסיכולוגיה החלה כבר בשנת 1995 במסגרות לטיפול, חונכות ותמיכה בילדים ומתבגרים.
מאז השתלבה בפרויקטים מורכבים ורגישים לטיפול בילדים, נוער ומבוגרים בגופים רבים כגון: השירות הפסיכולוגי של עיריית ירושלים, פרויקטים לאומיים של משרד הרווחה, מרכז מהות (מרכז הורים למתבגרים, מכון סאמיט), מכון שילה (ייעוץ וטיפול לציבור הדתי), ארגון "אל הנפש", פרויקט שבי"ל - שירותי בריאות ירושלמים למתבגרים, ועוד רבים.
שרה שימשה בתפקיד חברת הנהלה ורכזת טיפולית במשך 14 שנים בחוות הנוער הציוני לנוער עולה מצרפת, זאת לצד ניהול קליניקה פרטית לטיפול שיקום וסיוע פסיכולוגי ליחידים, זוגות, משפחות, מבוגרים ומתבגרים. בנוסף, שימשה כמרצה לקורסים בפסיכולוגיה במכללת ווינגייט ועודנה מעבירה מפגשים טיפוליים קבוצתיים, ומנחה קבוצות הורים במסגרות פרטיות וארגונים.
<br/><br/>
שרה מחזיקה בתואר ראשון (בהצטיינות) ושני בפסיכולוגיה, וכמובן רישיון לעסוק בפסיכולוגיה (27-85587) ממשרד הבריאות. 
בנוסף, מחזיקה בתעודת מטפלת זוגית-משפחתית ובהסמכה נוספת של האגודה הישראלית לטיפול זוגי ומשפחתי. 
הקליניקה של שרה ממוקמת בירושלים בשכונת פסגת זאב. סדנאות קבוצתיות, הרצאות ועוד- מועברות בכל הארץ לפי הצורך.
<br/><br/>
שרה גדלה בצרפת, עלתה לארץ בגיל 15 לבד, כיום היא נשואה ואם ל5, סבתא לנכדה ראשונה. 
היא אוהבת לסייע לאנשים, מדברת בגובה העיניים, מאמינה באדם וביכולת שלו להשתנות ולאהוב.
התחביב של שרה זה רכיבה על סוסים והיא עושה את זה בכל הזדמנות!
        </div>
        <div className="image">
          <img className="profile-img" src="/images/sarah.png" alt=""/>
          <div className="image-badge"></div>
          <img className="cert" src="/images/metapelet.png" alt=""/>
        </div>
      </div>

    </section>
  )
}

export default AboutSection
