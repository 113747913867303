import React from 'react';
import HeaderSection from '../components/HeaderSection';
import FooterSection from '../components/FooterSection.jsx';

const AccessibilityDeclaration = () => {
  return (
    <section>
      <HeaderSection type="accessibility" header="הצהרת נגישות"/>
      <div className="declaration-wrp">
      
      <h2> כללי:</h2>
 
        <p className="first-p">
        באתר זה הושקעו משאבים ומאמצים ניכרים על מנת להופכו לנגיש עבור אוכלוסיות נרחבות ככל הניתן.
        <br/>
לצורך כך עשינו ככל יכולתנו לעמידה בהנחיות תקן WCAG ברמת AA ואף השקענו משאבים נוספים וחשיבה רבה בכדי לפשט ולהנגיש את האתר לבעלי מוגבלויות שונות.
        </p>

        <h2> הנגשת האתר:</h2>
 
        <div className="access-type">
          כתיבת תוכן נהיר, קריא ומפורט
        </div>
        <div className="access-type">
         התאמה חזותית למגוון גדלי מסך וטווחי ביניים
        </div>
        <div className="access-type">
         עימוד האתר מחולק בצורה פשוטה ובולטת
        </div>
        <div className="access-type">
        האתר נבנה באמצעות תגיות HTML5
        </div>
        <div className="access-type">
        נעשו מאמצים לווידוא התאמה למגוון דפדפנים
        </div>
        <div className="access-type">
       הוספנו תגיות תיאור לתמונות ALT
        </div>
        <div className="access-type">
       ניתן לנווט בין כל  פונקציות האתר באמצעות 2 מקשים TAB + ENTER
        </div>
        <div className="access-type">
      נוסף תפריט נגישות הכולל מגוון אפשרויות הנגשה:
        </div>
     <div className="subs-wrp">
        <div className="access-type sub-access">
          ניתן לבצע הגדלה/הקטנה/ריווח של כלל הטקסטים באתר
            </div>
            <div className="access-type sub-access">
        ניתן להפוך צבעים/ניגודיות וכן להציג את האתר בגווני אפור
            </div>
            <div className="access-type sub-access">
        ניתן להגדיל את הסמן (עכבר), להבליט קישורים, וכן להציג סרגל סיוע בקריאה
            </div>
            <div className="access-type sub-access">
      ניתן להשתמש בקוראי מסכים ולהמיר טקסט לקול וההיפך
            </div>
     </div>
 
        <h2> תקלות, עדכוני תוכן והסתייגויות:</h2>

        <p className="first-p">
           
        מפעם בפעם מתרחשים שינויים טכנולוגיים המצריכים התאמות ועדכונים ברכיבי הנגישות.
        <br/>
        כמו כן, יתכן שיקרו תקלות טכניות ברכיבי הנגשה שונים, או שינויי תוכן/נראות אשר עלולים לפגום באיכות ההנגשה.
        <br/>
        אנו עושים מאמצים רבים על מנת להנגיש את האתר ואת התוכן המשתנה בו,
          <br/>
        אם נתקלתם בדבר מה המצריך את תשומת ליבנו - נשמח שתצרו קשר
        <a href="/#contact"> כאן </a>
        .
        </p>



      </div>
      <FooterSection/>
      
    </section>
  )
}

export default AccessibilityDeclaration;