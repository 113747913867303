import React, { useEffect, useState } from "react";
import ScrollButton from "./ScrollButton.jsx";

const FooterSection = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  
  const handleScroll = () => {
    if (window.scrollY >= 300) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  }

  return (
    <footer className="footer-section">
      <h3 className="text-contact">שרה טייב פסיכולוגית</h3>
      <div className="sub-text-contact">
        <h4>טיפול פרטני,</h4>
        <h4>טיפול זוגי ומשפחתי,</h4>
        <h4>גיל ההתבגרות</h4>
      </div>
      <div><a href="/accessibility"> הצהרת נגישות <i className="material-icons">accessible</i> </a></div>
      <div>כל הזכויות שמורות ©</div>
      <ScrollButton showScrollButton={showScrollButton}/> 
    </footer>
  )
}

export default FooterSection;
