import React, { useEffect } from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { Accessibility } from 'accessibility/src/main';
import Success from './pages/Success';
import Admin from './pages/Admin';
import ArticlesList from './pages/ArticlesList';
import Article from './pages/Article';
import Main from './pages/Main';
import Page404 from './pages/Page404';
import AccessibilityDeclaration from './pages/AccessibilityDeclaration';


function App() {

  useEffect(() => {
    window.addEventListener('load', function() {
        // new Accessibility({textPixelMode: true});
        new Accessibility(options);
    }, false);
    return () => {
      
    }
  }, [])

  return (
    <div className="container">
      <Router>
      <Switch>
        <Route exact path="/success" component={Success}></Route>
        <Route exact path="/accessibility" component={AccessibilityDeclaration}></Route>
        <Route exact path="/admin" component={Admin}></Route>
        <Route exact path="/articles" component={ArticlesList}></Route>
        <Route exact path="/articles/:id" component={Article}></Route>
        <Route exact path="/" component={Main}></Route>
        <Route exact path="*" component={Page404}></Route>
      </Switch>
    </Router>
    </div>
  );
}

export default App;

var labels = {
  resetTitle: 'חזרה למצב בסיס',
  closeTitle: 'סגירת חלון נגישות',
  menuTitle: 'תפריט נגישות:',
  increaseText: 'הגדלת טקסט',
  decreaseText: 'הקטנת טקסט',
  increaseTextSpacing: 'הגדלת מרווח בטקסט',
  decreaseTextSpacing: 'הקטנת מרווח בטקסט',
  invertColors: 'היפוך צבעים',
  grayHues: 'גווני אפור',
  underlineLinks: 'הדגשת קישורים',
  bigCursor: 'הגדלת מצביע',
  readingGuide: 'סרגל קריאה',
  textToSpeech: 'טקסט לקול',
  speechToText: 'קול לטקסט'
};
var options = { 
    labels: labels, 
    textPixelMode: true, 
    icon: {
      position: {
        bottom: { size: 15, units: 'px' },
        right: { size: 15, units: 'px' },
        type: 'fixed'
      }
    },
    session: {
      persistent: false
    }
  };
options.textToSpeechLang = 'he-IL'; // or any other language
options.speechToTextLang = 'he-IL'; // or any other language
