import React from 'react';

const ContactSection = () => {
  return (
    <section className="contact-section" id="contact">
      <div className="section-header">
        <h2>יצירת קשר</h2>
      </div>
      <div className="contact-wrapper">
        <div className="contact-form">
          <div className="text-wrp">
            הקליניקה ממוקמת בירושלים, בשכונת פסגת זאב.
            ניתן לקיים מפגשי ייעוץ מיוחדים, הדרכה קבוצתית ועוד - בירושלים ובאזורים נוספים בארץ.
          </div>
          <div className="phone-wrp">
            לתיאום פגישה או בירורים חייגו
            <a tabIndex="15" href="tel:0542400848">054-2400848</a>
          </div>
          <div className="mail-wrp">
            ניתן גם לשלוח פניה בדוא"ל
            <a tabIndex="16" href="mailto:sarata33@gmail.com">sarata33@gmail.com</a>
          </div>
        </div>
        <div className="image-wrp">
          <img src="images/phone.jpg" alt="contact"/>
        </div>
      </div>
    </section>
  )
}

export default ContactSection;
