import React from 'react'

const MethodsSection = () => {
  return (
    <section className="methods-section" id="methods">
      <div className="section-header">
      <h2>גישות ושיטות טיפוליות</h2>
      </div>
      <div className="methods-wrapper">
        <div className="method-item">
          <div className="method-icon">
            <img src="/images/spa.png" alt="תמונה לגישה הוליסטית"/>
          </div>
          <div className="method-text">
          טיפול בגישה הוליסטית
          </div>
        </div>
        <div className="method-item">
          <div className="method-icon">
            <img src="/images/psy.png" alt="תמונה לגישה בשיטת סי.בי.טי CBT"/>
          </div>
          <div className="method-text">
          טיפול בשיטת סי.בי.טי
          </div>
        </div>
        <div className="method-item">
          <div className="method-icon">
            <img src="/images/sofa.png" alt="תמונה לטיפול בגישה דינאמית"/>
          </div>
          <div className="method-text">
          טיפול בגישה הדינאמית
          </div>
        </div>
        <div className="method-item">
          <div className="method-icon">
           <img src="/images/personal.png" alt="תמונה לטיפול פרטני או זוגי"/>
          </div>
          <div className="method-text">
          טיפול פרטני או זוגי
          </div>
        </div>
        <div className="method-item">
          <div className="method-icon">
            <img src="/images/cards.png" alt="תמונה להמחשת טיפול בסיוע קלפים"/>
          </div>
          <div className="method-text">
          שילוב קלפים טיפוליים
          </div>
        </div>
        <div className="method-item">
          <div className="method-icon">
            <img src="/images/group.png" alt="תמונה להמחשת הדרכה קבוצתית טיפולית"/>
          </div>
          <div className="method-text">
          הדרכה קבוצתית טיפולית
          </div>
        </div>
      </div>
    </section>
  )
}

export default MethodsSection
